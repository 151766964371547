.modal_paper {
  min-width: 532px;
}
.success_icon {
  color: var(--danger-red-color);
}
.desktop_modal_header,
.mobile_modal_header {
  display: flex;
  align-items: center;
  height: 22px;
}

.desktop_modal_header {
  gap: 10.47px;
}

.mobile_modal_header {
  gap: 12.94px;
}

.desktop_success_wrapper,
.mobile_success_wrapper {
  display: flex;
  flex-direction: column;
}

.desktop_success_wrapper {
  gap: 9.81px;
  padding: 24px 0px;
}

.mobile_success_wrapper {
  gap: 1.81px;
  padding: 16px 0px;
  font-family: var(--font-family);
}
.desktop_success_wrapper svg,
.mobile_success_wrapper svg {
  font-size: 97.38px;
  color: #00b77a;
}
.primary_btn {
  background-color: #00b77a !important;
}
